<template>
  <el-form ref="form" :model="form" label-width="200px" class="elForm">
    <el-form-item label="技工姓名：" required="true">
      <el-input v-model="form.artisan_name" placeholder="" style="width: 600px"></el-input>
    </el-form-item>
    <el-form-item label="技工对应账号：" required="true">
      <el-input v-model="form.user_id" style="width: 530px" disabled></el-input>
      <el-button type="primary" @click="showUserList = !0">选择</el-button>
    </el-form-item>
    <el-form-item label="服务小区：" required="true">
      <el-select v-model="form.community_id" clearable placeholder="请选择服务小区" style="width: 600px">
        <el-option v-for="item in communityList" :key="item.id" :label="item.community_name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="联系电话：" required="true">
      <el-input v-model="form.mobile" placeholder="请填写手机号码" type="number" style="width: 600px"></el-input>
    </el-form-item>
    <el-form-item label="服务分类：" required="true">
      <el-select v-model="form.service_classify_id" multiple clearable placeholder="请选择服务分类" style="width: 600px">
        <el-option v-for="item in ClassifyList" :key="item.id" :label="item.classify_name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    
    <el-form-item label="所在地址：" required="true">
      <el-input v-model="form.address" placeholder="请填写所在地址" style="width: 600px"></el-input>
    </el-form-item>
    <el-form-item label="职称：" required="true">
      <el-input v-model="form.job_title" placeholder="请填写职称" style="width: 600px"></el-input>
    </el-form-item>
    <el-form-item label="从事维修工作年限：" required="true">
      <el-input v-model="form.years_of_working" placeholder="请填写工作年限" type="number" style="width: 600px"></el-input>
    </el-form-item>
    <el-form-item label="个人头像：" required="true">
      <ReadyUploadSource @getSource="val => (form.avatar = val.path)" :path="form.avatar" @removeThis="() => (form.avatar = '')"></ReadyUploadSource>
    </el-form-item>
    <!-- 选择用户 -->
    <el-dialog title="选择用户" :visible.sync="showUserList" width="54%">
      <el-form class="el-form-search" label-width="110px">
        <el-form-item label="用户名称：">
          <el-input size="small" placeholder="请输入用户昵称" v-model="user_form.name"></el-input>
        </el-form-item>
        <el-form-item label="用户手机：">
          <el-input size="small" placeholder="请输入用户电话" v-model="user_form.mobile"></el-input>
        </el-form-item>
        <el-form-item label=" " label-width="20px">
          <el-button type="primary" size="small" @click="searchUser">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="user_list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="id" label="用户ID" align="center"></el-table-column>
        <el-table-column prop="avatar" label="用户头像" align="center">
          <template slot-scope="scope">
            <el-image :src="scope.row.avatar" class="avatar"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="用户名称" align="center"></el-table-column>
        <el-table-column prop="mobile" label="用户手机" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="chooseUser(scope.row.id)" type="text" size="small">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="user_total_number" :page="user_form.page" :pageNum="user_form.rows" @updatePageNum="updateDataUser"></Paging>
    </el-dialog>
    <Preservation @preservation="preservation"></Preservation>
  </el-form>
</template>
<script>
import _ from 'lodash';
import Paging from '@/components/paging';
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Paging,
    Preservation,
    ReadyUploadSource
  },
  data() {
    return {
      showUserList: !1,
      user_form: {
        page: 1,
        rows: 5,
        name: "",
        mobile: '',
      },
      user_list:[],
      user_total_number: 0,
      form: {
        user_id: '',
        artisan_name: '',
        mobile: '',
        service_classify_id: [],
        address: '',
        job_title: '',
        years_of_working: '',
        avatar: '',
        community_id: ''
      },
      ClassifyList: [],
      //选中服务区域
      serviceAreaList: [{
        id: 1,
        service_area: [],
      }],
      communityList:[]
    };
  },
  created() {
    this.getUserList();
    // this.getArea();
    this.getClassifyList();
    this.getCommunity();
  },
  methods: {
    searchUser(){
        this.user_form.page = 1
        this.getUserList()
    },
    getUserList(){
        this.$axios.post(this.$api.user.userList, this.user_form).then(res => {
          if (res.code == 0) {
            this.user_list = res.result.list
            this.user_total_number = res.result.total
          }else{
            this.$message.error(111, res.msg);
          }
        });
    },
    updateDataUser(val, status){
        if (status == 0) {
            this.user_form.rows = val;
            this.getUserList();
        } else {
            this.user_form.page = val;
            this.getUserList();
        }
    },
    chooseUser(id){
        this.form.user_id = id;
        this.showUserList = !1;
    },
    getCommunity() {
      this.$axios.post(this.$api.user.communityList).then(res => {
        if (res.code == 0) {
          this.communityList = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 添加服务区域
    addService() {
      this.serviceAreaList.push({
        id: this.serviceAreaList.length+1,
      });
    },
    // 删除服务区域
    delService(id) {
      let index = this.serviceAreaList.findIndex(item => item.id == id);
      this.serviceAreaList.splice(index, 1);
    },
    getClassifyList(){
        this.$axios.post(this.$api.user.classifyList, {page: 1, rows: 10000, is_show: 1}).then(res => {
          if (res.code == 0) {
            this.ClassifyList = res.result
          }else{
            this.$message.error(res.msg);
          }
        });
    },
    // 调用接口时检测数据是否合法
    checkDataLegitimacy () {
      if (!this.form.artisan_name) {
        this.$message.warning('请填写技工姓名');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.form.mobile) {
        this.$message.warning('请填写联系电话');
        this.activeTabs = 'one';
        return false;
      }
      if (this.form.service_classify_id.length == 0) {
        this.$message.warning('请选择服务分类');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.form.address) {
        this.$message.warning('请填写所在地址');
        this.activeTabs = 'one';
        return false;
      }
    },
    preservation() {
      let checkRes = this.checkDataLegitimacy();
      if (checkRes === false) return;

      let data = {
        user_id: this.form.user_id,
        artisan_name: this.form.artisan_name,
        mobile: this.form.mobile,
        service_classify_id: this.form.service_classify_id,
        community_id: this.form.community_id,
        address: this.form.address,
        job_title: this.form.job_title,
        years_of_working: this.form.years_of_working,
        avatar: this.form.avatar,
      };
      console.log(data);
      this.$axios.post(this.$api.user.addArtisan, data).then(res => {
        if (res.code == 0) {
          this.$message.success('添加成功');
          this.$router.push('/user/settedList');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
span {
  color: gray;
}
.elForm {
  background-color: #fff;
  padding: 30px;
}
</style>
